:root {
  --spectrum-overlay-opacity: .5;
  --spectrum-gray-background-color-default: var(--spectrum-gray-700);
  --spectrum-red-background-color-default: var(--spectrum-red-700);
  --spectrum-orange-background-color-default: var(--spectrum-orange-800);
  --spectrum-yellow-background-color-default: var(--spectrum-yellow-1000);
  --spectrum-chartreuse-background-color-default: var(--spectrum-chartreuse-900);
  --spectrum-celery-background-color-default: var(--spectrum-celery-800);
  --spectrum-green-background-color-default: var(--spectrum-green-700);
  --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-700);
  --spectrum-cyan-background-color-default: var(--spectrum-cyan-700);
  --spectrum-blue-background-color-default: var(--spectrum-blue-700);
  --spectrum-indigo-background-color-default: var(--spectrum-indigo-700);
  --spectrum-purple-background-color-default: var(--spectrum-purple-700);
  --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-700);
  --spectrum-magenta-background-color-default: var(--spectrum-magenta-700);
  --spectrum-neutral-background-color-default: var(--spectrum-gray-400);
  --spectrum-neutral-background-color-hover: var(--spectrum-gray-300);
  --spectrum-neutral-background-color-down: var(--spectrum-gray-200);
  --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-300);
  --spectrum-neutral-subdued-background-color-default: var(--spectrum-gray-400);
  --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-300);
  --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-200);
  --spectrum-neutral-subdued-background-color-key-focus: var(--spectrum-gray-300);
  --spectrum-accent-background-color-default: var(--spectrum-accent-color-500);
  --spectrum-accent-background-color-hover: var(--spectrum-accent-color-400);
  --spectrum-accent-background-color-down: var(--spectrum-accent-color-300);
  --spectrum-accent-background-color-key-focus: var(--spectrum-accent-color-400);
  --spectrum-informative-background-color-default: var(--spectrum-informative-color-500);
  --spectrum-informative-background-color-hover: var(--spectrum-informative-color-400);
  --spectrum-informative-background-color-down: var(--spectrum-informative-color-300);
  --spectrum-informative-background-color-key-focus: var(--spectrum-informative-color-400);
  --spectrum-negative-background-color-default: var(--spectrum-negative-color-500);
  --spectrum-negative-background-color-hover: var(--spectrum-negative-color-400);
  --spectrum-negative-background-color-down: var(--spectrum-negative-color-300);
  --spectrum-negative-background-color-key-focus: var(--spectrum-negative-color-400);
  --spectrum-positive-background-color-default: var(--spectrum-positive-color-500);
  --spectrum-positive-background-color-hover: var(--spectrum-positive-color-400);
  --spectrum-positive-background-color-down: var(--spectrum-positive-color-300);
  --spectrum-positive-background-color-key-focus: var(--spectrum-positive-color-400);
  --spectrum-neutral-visual-color: var(--spectrum-gray-600);
  --spectrum-accent-visual-color: var(--spectrum-accent-color-900);
  --spectrum-informative-visual-color: var(--spectrum-informative-color-900);
  --spectrum-negative-visual-color: var(--spectrum-negative-color-700);
  --spectrum-notice-visual-color: var(--spectrum-notice-color-900);
  --spectrum-positive-visual-color: var(--spectrum-positive-color-800);
  --spectrum-gray-visual-color: var(--spectrum-gray-600);
  --spectrum-red-visual-color: var(--spectrum-red-700);
  --spectrum-orange-visual-color: var(--spectrum-orange-900);
  --spectrum-yellow-visual-color: var(--spectrum-yellow-1100);
  --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-900);
  --spectrum-celery-visual-color: var(--spectrum-celery-800);
  --spectrum-green-visual-color: var(--spectrum-green-800);
  --spectrum-seafoam-visual-color: var(--spectrum-seafoam-800);
  --spectrum-cyan-visual-color: var(--spectrum-cyan-900);
  --spectrum-blue-visual-color: var(--spectrum-blue-900);
  --spectrum-indigo-visual-color: var(--spectrum-indigo-900);
  --spectrum-purple-visual-color: var(--spectrum-purple-900);
  --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-900);
  --spectrum-magenta-visual-color: var(--spectrum-magenta-900);
  --spectrum-drop-shadow-color: rgba(0, 0, 0, .5);
  --spectrum-background-base-color: var(--spectrum-gray-50);
  --spectrum-background-layer-1-color: var(--spectrum-gray-75);
  --spectrum-background-layer-2-color: var(--spectrum-gray-100);
  --spectrum-gray-50: #1d1d1d;
  --spectrum-gray-75: #262626;
  --spectrum-gray-100: #323232;
  --spectrum-gray-200: #3f3f3f;
  --spectrum-gray-300: #545454;
  --spectrum-gray-400: #707070;
  --spectrum-gray-500: #909090;
  --spectrum-gray-600: #b2b2b2;
  --spectrum-gray-700: #d1d1d1;
  --spectrum-gray-800: #ebebeb;
  --spectrum-gray-900: #fff;
  --spectrum-red-100: #7b0000;
  --spectrum-red-200: #8d0000;
  --spectrum-red-300: #a50000;
  --spectrum-red-400: #be0403;
  --spectrum-red-500: #d71913;
  --spectrum-red-600: #ea3829;
  --spectrum-red-700: #f65843;
  --spectrum-red-800: #ff755e;
  --spectrum-red-900: #ff9581;
  --spectrum-red-1000: #ffb0a1;
  --spectrum-red-1100: #ffc9bd;
  --spectrum-red-1200: #ffded8;
  --spectrum-red-1300: #fff1ee;
  --spectrum-red-1400: #fff;
  --spectrum-orange-100: #662500;
  --spectrum-orange-200: #752d00;
  --spectrum-orange-300: #893700;
  --spectrum-orange-400: #9e4200;
  --spectrum-orange-500: #b44e00;
  --spectrum-orange-600: #ca5d00;
  --spectrum-orange-700: #e16d00;
  --spectrum-orange-800: #f4810c;
  --spectrum-orange-900: #fe9a2e;
  --spectrum-orange-1000: #ffb558;
  --spectrum-orange-1100: #fdce88;
  --spectrum-orange-1200: #ffe1b3;
  --spectrum-orange-1300: #fff2dd;
  --spectrum-orange-1400: #fffdf9;
  --spectrum-yellow-100: #4c3600;
  --spectrum-yellow-200: #584000;
  --spectrum-yellow-300: #674c00;
  --spectrum-yellow-400: #775900;
  --spectrum-yellow-500: #886800;
  --spectrum-yellow-600: #9b7800;
  --spectrum-yellow-700: #ae8900;
  --spectrum-yellow-800: #c09c00;
  --spectrum-yellow-900: #d3ae00;
  --spectrum-yellow-1000: #e4c200;
  --spectrum-yellow-1100: #f4d500;
  --spectrum-yellow-1200: #f9e85c;
  --spectrum-yellow-1300: #fcf6bb;
  --spectrum-yellow-1400: #fff;
  --spectrum-chartreuse-100: #304000;
  --spectrum-chartreuse-200: #374a00;
  --spectrum-chartreuse-300: #415700;
  --spectrum-chartreuse-400: #4c6600;
  --spectrum-chartreuse-500: #597600;
  --spectrum-chartreuse-600: #680;
  --spectrum-chartreuse-700: #759a00;
  --spectrum-chartreuse-800: #84ad01;
  --spectrum-chartreuse-900: #94c008;
  --spectrum-chartreuse-1000: #a6d312;
  --spectrum-chartreuse-1100: #b8e525;
  --spectrum-chartreuse-1200: #cdf547;
  --spectrum-chartreuse-1300: #e7fe9a;
  --spectrum-chartreuse-1400: #fff;
  --spectrum-celery-100: #00450a;
  --spectrum-celery-200: #00500c;
  --spectrum-celery-300: #005e0e;
  --spectrum-celery-400: #006d0f;
  --spectrum-celery-500: #007f0f;
  --spectrum-celery-600: #009112;
  --spectrum-celery-700: #04a51e;
  --spectrum-celery-800: #22b833;
  --spectrum-celery-900: #44ca49;
  --spectrum-celery-1000: #69dc63;
  --spectrum-celery-1100: #8eeb7f;
  --spectrum-celery-1200: #b4f7a2;
  --spectrum-celery-1300: #ddfdd3;
  --spectrum-celery-1400: #fff;
  --spectrum-green-100: #044329;
  --spectrum-green-200: #004e2f;
  --spectrum-green-300: #005c38;
  --spectrum-green-400: #006c43;
  --spectrum-green-500: #007d4e;
  --spectrum-green-600: #008f5d;
  --spectrum-green-700: #12a26c;
  --spectrum-green-800: #2bb47d;
  --spectrum-green-900: #43c78f;
  --spectrum-green-1000: #5ed9a2;
  --spectrum-green-1100: #81e9b8;
  --spectrum-green-1200: #b1f4d1;
  --spectrum-green-1300: #dffaea;
  --spectrum-green-1400: #fefffc;
  --spectrum-seafoam-100: #12413f;
  --spectrum-seafoam-200: #0e4c49;
  --spectrum-seafoam-300: #045a57;
  --spectrum-seafoam-400: #006965;
  --spectrum-seafoam-500: #007a75;
  --spectrum-seafoam-600: #008c87;
  --spectrum-seafoam-700: #009e98;
  --spectrum-seafoam-800: #03b2ab;
  --spectrum-seafoam-900: #36c5bd;
  --spectrum-seafoam-1000: #5dd6cf;
  --spectrum-seafoam-1100: #84e6df;
  --spectrum-seafoam-1200: #b0f2ec;
  --spectrum-seafoam-1300: #dff9f6;
  --spectrum-seafoam-1400: #fefffe;
  --spectrum-cyan-100: #003d62;
  --spectrum-cyan-200: #00476f;
  --spectrum-cyan-300: #00557f;
  --spectrum-cyan-400: #006491;
  --spectrum-cyan-500: #0074a2;
  --spectrum-cyan-600: #0086b4;
  --spectrum-cyan-700: #0099c6;
  --spectrum-cyan-800: #0eadd7;
  --spectrum-cyan-900: #2cc1e6;
  --spectrum-cyan-1000: #54d3f1;
  --spectrum-cyan-1100: #7fe4f9;
  --spectrum-cyan-1200: #a7f1ff;
  --spectrum-cyan-1300: #d7faff;
  --spectrum-cyan-1400: #fff;
  --spectrum-blue-100: #003877;
  --spectrum-blue-200: #00418a;
  --spectrum-blue-300: #004da3;
  --spectrum-blue-400: #0059c2;
  --spectrum-blue-500: #0367e0;
  --spectrum-blue-600: #1379f3;
  --spectrum-blue-700: #348ff4;
  --spectrum-blue-800: #54a3f6;
  --spectrum-blue-900: #72b7f9;
  --spectrum-blue-1000: #8fcafc;
  --spectrum-blue-1100: #aedbfe;
  --spectrum-blue-1200: #cce9ff;
  --spectrum-blue-1300: #e8f6ff;
  --spectrum-blue-1400: #fff;
  --spectrum-indigo-100: #282c8c;
  --spectrum-indigo-200: #2f34a3;
  --spectrum-indigo-300: #393fbb;
  --spectrum-indigo-400: #464bd3;
  --spectrum-indigo-500: #555be7;
  --spectrum-indigo-600: #686df4;
  --spectrum-indigo-700: #7c81fb;
  --spectrum-indigo-800: #9195ff;
  --spectrum-indigo-900: #a7aaff;
  --spectrum-indigo-1000: #bcbeff;
  --spectrum-indigo-1100: #d0d2ff;
  --spectrum-indigo-1200: #e2e4ff;
  --spectrum-indigo-1300: #f3f3fe;
  --spectrum-indigo-1400: #fff;
  --spectrum-purple-100: #4c0d9d;
  --spectrum-purple-200: #5911b1;
  --spectrum-purple-300: #691cc8;
  --spectrum-purple-400: #7a2dda;
  --spectrum-purple-500: #8c41e9;
  --spectrum-purple-600: #9d57f3;
  --spectrum-purple-700: #ac6ff9;
  --spectrum-purple-800: #bb87fb;
  --spectrum-purple-900: #ca9ffc;
  --spectrum-purple-1000: #d7b6fe;
  --spectrum-purple-1100: #e4ccfe;
  --spectrum-purple-1200: #efdfff;
  --spectrum-purple-1300: #f9f0ff;
  --spectrum-purple-1400: #fffdff;
  --spectrum-fuchsia-100: #6b036a;
  --spectrum-fuchsia-200: #7b007b;
  --spectrum-fuchsia-300: #900091;
  --spectrum-fuchsia-400: #a50da6;
  --spectrum-fuchsia-500: #b925b9;
  --spectrum-fuchsia-600: #cd39ce;
  --spectrum-fuchsia-700: #df51e0;
  --spectrum-fuchsia-800: #eb6eec;
  --spectrum-fuchsia-900: #f48cf2;
  --spectrum-fuchsia-1000: #faa8f5;
  --spectrum-fuchsia-1100: #fec2f8;
  --spectrum-fuchsia-1200: #ffdbfa;
  --spectrum-fuchsia-1300: #ffeffc;
  --spectrum-fuchsia-1400: #fffdff;
  --spectrum-magenta-100: #76003a;
  --spectrum-magenta-200: #890042;
  --spectrum-magenta-300: #a0004d;
  --spectrum-magenta-400: #b6125a;
  --spectrum-magenta-500: #cb266d;
  --spectrum-magenta-600: #de3d82;
  --spectrum-magenta-700: #ed5795;
  --spectrum-magenta-800: #f972a7;
  --spectrum-magenta-900: #ff8fb9;
  --spectrum-magenta-1000: #ffacca;
  --spectrum-magenta-1100: #ffc6da;
  --spectrum-magenta-1200: #ffdde9;
  --spectrum-magenta-1300: #fff0f5;
  --spectrum-magenta-1400: #fffcfd;
}

:host {
  --spectrum-overlay-opacity: .5;
  --spectrum-gray-background-color-default: var(--spectrum-gray-700);
  --spectrum-red-background-color-default: var(--spectrum-red-700);
  --spectrum-orange-background-color-default: var(--spectrum-orange-800);
  --spectrum-yellow-background-color-default: var(--spectrum-yellow-1000);
  --spectrum-chartreuse-background-color-default: var(--spectrum-chartreuse-900);
  --spectrum-celery-background-color-default: var(--spectrum-celery-800);
  --spectrum-green-background-color-default: var(--spectrum-green-700);
  --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-700);
  --spectrum-cyan-background-color-default: var(--spectrum-cyan-700);
  --spectrum-blue-background-color-default: var(--spectrum-blue-700);
  --spectrum-indigo-background-color-default: var(--spectrum-indigo-700);
  --spectrum-purple-background-color-default: var(--spectrum-purple-700);
  --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-700);
  --spectrum-magenta-background-color-default: var(--spectrum-magenta-700);
  --spectrum-neutral-background-color-default: var(--spectrum-gray-400);
  --spectrum-neutral-background-color-hover: var(--spectrum-gray-300);
  --spectrum-neutral-background-color-down: var(--spectrum-gray-200);
  --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-300);
  --spectrum-neutral-subdued-background-color-default: var(--spectrum-gray-400);
  --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-300);
  --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-200);
  --spectrum-neutral-subdued-background-color-key-focus: var(--spectrum-gray-300);
  --spectrum-accent-background-color-default: var(--spectrum-accent-color-500);
  --spectrum-accent-background-color-hover: var(--spectrum-accent-color-400);
  --spectrum-accent-background-color-down: var(--spectrum-accent-color-300);
  --spectrum-accent-background-color-key-focus: var(--spectrum-accent-color-400);
  --spectrum-informative-background-color-default: var(--spectrum-informative-color-500);
  --spectrum-informative-background-color-hover: var(--spectrum-informative-color-400);
  --spectrum-informative-background-color-down: var(--spectrum-informative-color-300);
  --spectrum-informative-background-color-key-focus: var(--spectrum-informative-color-400);
  --spectrum-negative-background-color-default: var(--spectrum-negative-color-500);
  --spectrum-negative-background-color-hover: var(--spectrum-negative-color-400);
  --spectrum-negative-background-color-down: var(--spectrum-negative-color-300);
  --spectrum-negative-background-color-key-focus: var(--spectrum-negative-color-400);
  --spectrum-positive-background-color-default: var(--spectrum-positive-color-500);
  --spectrum-positive-background-color-hover: var(--spectrum-positive-color-400);
  --spectrum-positive-background-color-down: var(--spectrum-positive-color-300);
  --spectrum-positive-background-color-key-focus: var(--spectrum-positive-color-400);
  --spectrum-neutral-visual-color: var(--spectrum-gray-600);
  --spectrum-accent-visual-color: var(--spectrum-accent-color-900);
  --spectrum-informative-visual-color: var(--spectrum-informative-color-900);
  --spectrum-negative-visual-color: var(--spectrum-negative-color-700);
  --spectrum-notice-visual-color: var(--spectrum-notice-color-900);
  --spectrum-positive-visual-color: var(--spectrum-positive-color-800);
  --spectrum-gray-visual-color: var(--spectrum-gray-600);
  --spectrum-red-visual-color: var(--spectrum-red-700);
  --spectrum-orange-visual-color: var(--spectrum-orange-900);
  --spectrum-yellow-visual-color: var(--spectrum-yellow-1100);
  --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-900);
  --spectrum-celery-visual-color: var(--spectrum-celery-800);
  --spectrum-green-visual-color: var(--spectrum-green-800);
  --spectrum-seafoam-visual-color: var(--spectrum-seafoam-800);
  --spectrum-cyan-visual-color: var(--spectrum-cyan-900);
  --spectrum-blue-visual-color: var(--spectrum-blue-900);
  --spectrum-indigo-visual-color: var(--spectrum-indigo-900);
  --spectrum-purple-visual-color: var(--spectrum-purple-900);
  --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-900);
  --spectrum-magenta-visual-color: var(--spectrum-magenta-900);
  --spectrum-drop-shadow-color: rgba(0, 0, 0, .5);
  --spectrum-background-base-color: var(--spectrum-gray-50);
  --spectrum-background-layer-1-color: var(--spectrum-gray-75);
  --spectrum-background-layer-2-color: var(--spectrum-gray-100);
  --spectrum-gray-50: #1d1d1d;
  --spectrum-gray-75: #262626;
  --spectrum-gray-100: #323232;
  --spectrum-gray-200: #3f3f3f;
  --spectrum-gray-300: #545454;
  --spectrum-gray-400: #707070;
  --spectrum-gray-500: #909090;
  --spectrum-gray-600: #b2b2b2;
  --spectrum-gray-700: #d1d1d1;
  --spectrum-gray-800: #ebebeb;
  --spectrum-gray-900: #fff;
  --spectrum-red-100: #7b0000;
  --spectrum-red-200: #8d0000;
  --spectrum-red-300: #a50000;
  --spectrum-red-400: #be0403;
  --spectrum-red-500: #d71913;
  --spectrum-red-600: #ea3829;
  --spectrum-red-700: #f65843;
  --spectrum-red-800: #ff755e;
  --spectrum-red-900: #ff9581;
  --spectrum-red-1000: #ffb0a1;
  --spectrum-red-1100: #ffc9bd;
  --spectrum-red-1200: #ffded8;
  --spectrum-red-1300: #fff1ee;
  --spectrum-red-1400: #fff;
  --spectrum-orange-100: #662500;
  --spectrum-orange-200: #752d00;
  --spectrum-orange-300: #893700;
  --spectrum-orange-400: #9e4200;
  --spectrum-orange-500: #b44e00;
  --spectrum-orange-600: #ca5d00;
  --spectrum-orange-700: #e16d00;
  --spectrum-orange-800: #f4810c;
  --spectrum-orange-900: #fe9a2e;
  --spectrum-orange-1000: #ffb558;
  --spectrum-orange-1100: #fdce88;
  --spectrum-orange-1200: #ffe1b3;
  --spectrum-orange-1300: #fff2dd;
  --spectrum-orange-1400: #fffdf9;
  --spectrum-yellow-100: #4c3600;
  --spectrum-yellow-200: #584000;
  --spectrum-yellow-300: #674c00;
  --spectrum-yellow-400: #775900;
  --spectrum-yellow-500: #886800;
  --spectrum-yellow-600: #9b7800;
  --spectrum-yellow-700: #ae8900;
  --spectrum-yellow-800: #c09c00;
  --spectrum-yellow-900: #d3ae00;
  --spectrum-yellow-1000: #e4c200;
  --spectrum-yellow-1100: #f4d500;
  --spectrum-yellow-1200: #f9e85c;
  --spectrum-yellow-1300: #fcf6bb;
  --spectrum-yellow-1400: #fff;
  --spectrum-chartreuse-100: #304000;
  --spectrum-chartreuse-200: #374a00;
  --spectrum-chartreuse-300: #415700;
  --spectrum-chartreuse-400: #4c6600;
  --spectrum-chartreuse-500: #597600;
  --spectrum-chartreuse-600: #680;
  --spectrum-chartreuse-700: #759a00;
  --spectrum-chartreuse-800: #84ad01;
  --spectrum-chartreuse-900: #94c008;
  --spectrum-chartreuse-1000: #a6d312;
  --spectrum-chartreuse-1100: #b8e525;
  --spectrum-chartreuse-1200: #cdf547;
  --spectrum-chartreuse-1300: #e7fe9a;
  --spectrum-chartreuse-1400: #fff;
  --spectrum-celery-100: #00450a;
  --spectrum-celery-200: #00500c;
  --spectrum-celery-300: #005e0e;
  --spectrum-celery-400: #006d0f;
  --spectrum-celery-500: #007f0f;
  --spectrum-celery-600: #009112;
  --spectrum-celery-700: #04a51e;
  --spectrum-celery-800: #22b833;
  --spectrum-celery-900: #44ca49;
  --spectrum-celery-1000: #69dc63;
  --spectrum-celery-1100: #8eeb7f;
  --spectrum-celery-1200: #b4f7a2;
  --spectrum-celery-1300: #ddfdd3;
  --spectrum-celery-1400: #fff;
  --spectrum-green-100: #044329;
  --spectrum-green-200: #004e2f;
  --spectrum-green-300: #005c38;
  --spectrum-green-400: #006c43;
  --spectrum-green-500: #007d4e;
  --spectrum-green-600: #008f5d;
  --spectrum-green-700: #12a26c;
  --spectrum-green-800: #2bb47d;
  --spectrum-green-900: #43c78f;
  --spectrum-green-1000: #5ed9a2;
  --spectrum-green-1100: #81e9b8;
  --spectrum-green-1200: #b1f4d1;
  --spectrum-green-1300: #dffaea;
  --spectrum-green-1400: #fefffc;
  --spectrum-seafoam-100: #12413f;
  --spectrum-seafoam-200: #0e4c49;
  --spectrum-seafoam-300: #045a57;
  --spectrum-seafoam-400: #006965;
  --spectrum-seafoam-500: #007a75;
  --spectrum-seafoam-600: #008c87;
  --spectrum-seafoam-700: #009e98;
  --spectrum-seafoam-800: #03b2ab;
  --spectrum-seafoam-900: #36c5bd;
  --spectrum-seafoam-1000: #5dd6cf;
  --spectrum-seafoam-1100: #84e6df;
  --spectrum-seafoam-1200: #b0f2ec;
  --spectrum-seafoam-1300: #dff9f6;
  --spectrum-seafoam-1400: #fefffe;
  --spectrum-cyan-100: #003d62;
  --spectrum-cyan-200: #00476f;
  --spectrum-cyan-300: #00557f;
  --spectrum-cyan-400: #006491;
  --spectrum-cyan-500: #0074a2;
  --spectrum-cyan-600: #0086b4;
  --spectrum-cyan-700: #0099c6;
  --spectrum-cyan-800: #0eadd7;
  --spectrum-cyan-900: #2cc1e6;
  --spectrum-cyan-1000: #54d3f1;
  --spectrum-cyan-1100: #7fe4f9;
  --spectrum-cyan-1200: #a7f1ff;
  --spectrum-cyan-1300: #d7faff;
  --spectrum-cyan-1400: #fff;
  --spectrum-blue-100: #003877;
  --spectrum-blue-200: #00418a;
  --spectrum-blue-300: #004da3;
  --spectrum-blue-400: #0059c2;
  --spectrum-blue-500: #0367e0;
  --spectrum-blue-600: #1379f3;
  --spectrum-blue-700: #348ff4;
  --spectrum-blue-800: #54a3f6;
  --spectrum-blue-900: #72b7f9;
  --spectrum-blue-1000: #8fcafc;
  --spectrum-blue-1100: #aedbfe;
  --spectrum-blue-1200: #cce9ff;
  --spectrum-blue-1300: #e8f6ff;
  --spectrum-blue-1400: #fff;
  --spectrum-indigo-100: #282c8c;
  --spectrum-indigo-200: #2f34a3;
  --spectrum-indigo-300: #393fbb;
  --spectrum-indigo-400: #464bd3;
  --spectrum-indigo-500: #555be7;
  --spectrum-indigo-600: #686df4;
  --spectrum-indigo-700: #7c81fb;
  --spectrum-indigo-800: #9195ff;
  --spectrum-indigo-900: #a7aaff;
  --spectrum-indigo-1000: #bcbeff;
  --spectrum-indigo-1100: #d0d2ff;
  --spectrum-indigo-1200: #e2e4ff;
  --spectrum-indigo-1300: #f3f3fe;
  --spectrum-indigo-1400: #fff;
  --spectrum-purple-100: #4c0d9d;
  --spectrum-purple-200: #5911b1;
  --spectrum-purple-300: #691cc8;
  --spectrum-purple-400: #7a2dda;
  --spectrum-purple-500: #8c41e9;
  --spectrum-purple-600: #9d57f3;
  --spectrum-purple-700: #ac6ff9;
  --spectrum-purple-800: #bb87fb;
  --spectrum-purple-900: #ca9ffc;
  --spectrum-purple-1000: #d7b6fe;
  --spectrum-purple-1100: #e4ccfe;
  --spectrum-purple-1200: #efdfff;
  --spectrum-purple-1300: #f9f0ff;
  --spectrum-purple-1400: #fffdff;
  --spectrum-fuchsia-100: #6b036a;
  --spectrum-fuchsia-200: #7b007b;
  --spectrum-fuchsia-300: #900091;
  --spectrum-fuchsia-400: #a50da6;
  --spectrum-fuchsia-500: #b925b9;
  --spectrum-fuchsia-600: #cd39ce;
  --spectrum-fuchsia-700: #df51e0;
  --spectrum-fuchsia-800: #eb6eec;
  --spectrum-fuchsia-900: #f48cf2;
  --spectrum-fuchsia-1000: #faa8f5;
  --spectrum-fuchsia-1100: #fec2f8;
  --spectrum-fuchsia-1200: #ffdbfa;
  --spectrum-fuchsia-1300: #ffeffc;
  --spectrum-fuchsia-1400: #fffdff;
  --spectrum-magenta-100: #76003a;
  --spectrum-magenta-200: #890042;
  --spectrum-magenta-300: #a0004d;
  --spectrum-magenta-400: #b6125a;
  --spectrum-magenta-500: #cb266d;
  --spectrum-magenta-600: #de3d82;
  --spectrum-magenta-700: #ed5795;
  --spectrum-magenta-800: #f972a7;
  --spectrum-magenta-900: #ff8fb9;
  --spectrum-magenta-1000: #ffacca;
  --spectrum-magenta-1100: #ffc6da;
  --spectrum-magenta-1200: #ffdde9;
  --spectrum-magenta-1300: #fff0f5;
  --spectrum-magenta-1400: #fffcfd;
}



/*# sourceMappingURL=dark-theme.4afcdfde.css.map */
